@import 'css/lib/tailwind-custom/triangle';

@media screen {
	.interstitial-content-container.wishlist {
		.product-image {
			width: 100%;
			max-width: 480px;
			max-height: 256px;
		}

		.product-image,
		.product-name {
			margin-bottom: 20px;
		}

		.wishlist-interstitial-form-container {
			margin-top: 20px;

			form.wishlistIntertstitialForm {
				select {
					width: auto;
				}
			}
		}

		.interstitial-controls {
			margin-top: 10px;

			input,
			a,
			button {
				margin-top: 10px;
				margin-bottom: 10px;
				margin-right: 10px;
			}
		}
	}
}

/* New Modal */
@media screen {
	.added-to-wishlist-content {
		.added-to-wishlist {
			display: grid;

			grid-template-columns: 472px 261px;

			.product-image {
				max-width: 76%;
			}
		}

		.added-to-wishlist-actions {
			margin-top: 20px;
		}

		.room-assignment-form {
			@apply tw-border-t tw-border-gray-400;
			padding-top: 21px;

			&.room-assignment-form-expanded {
				.room-assignment-form-header {
					&::before {
						@include triangle(7px, theme('colors.gray.DEFAULT'), down);
						border-top-width: 12px;
						display: inline-block;
						position: absolute;
						top: calc(50% - 6px);
						left: 0;
					}
				}

				.room-assignment-form-body {
					display: block;
					margin-top: 15px;
				}
			}

			.room-assignment-form-body {
				display: none;
			}
		}

		.room-assignment-form-header {
			position: relative;
			padding-left: 20px;
			&::before {
				@include triangle(8px, theme('colors.gray.DEFAULT'), right);
				border-left-width: 12px;
				display: inline-block;
				position: absolute;
				top: calc(50% - 6px);
				left: 0;
			}
		}

		:global .flexed-field-line,
		:global .existingRoomName-field-group,
		:global .newRoomName-field-group {
			display: flex;
			align-items: center;
		}

		:global .newRoomName-field-group {
			input {
				flex: 1 1 auto;
			}
		}

		:global .existingRoomName-field-group,
		:global .newRoomName-field-group {
			width: 302px;
			margin-right: 15px;
			margin-bottom: 0;

			label {
				flex: 0 0 auto;
				margin-right: 10px;
			}
		}

		.added-to-wishlist {
			grid-template-columns: 1fr;
		}
	
		.added-to-wishlist-right-column {
			padding: 15px 0 32px 0;
			border-left: none;
		}
	
		.room-assignment-form {
			border-top: solid 1px theme('colors.gray.400');
	
			.room-assignment-form-header.Heading.Heading--4 {
				font-size: 19px;
			}
	
			.room-assignment-form-header {
				&:before {
					@include triangle(6px, theme('colors.gray.DEFAULT'), right);
					border-left-width: 10px;
				}
			}
	
			&.room-assignment-form-expanded {
				.room-assignment-form-header {
					&:before {
						@include triangle(6px, theme('colors.gray.DEFAULT'), down);
						border-top-width: 10px;
					}
				}
			}
	
			.flexed-field-line {
				margin-bottom: 19px;
			}
		}
	}

	.view-state-container-CUSTOM_SECTIONAL_BUILDER {
		.added-to-wishlist-content {
			.added-to-wishlist {
				.product-image {
					max-width: 100%;
				}
			}
		}
	}
}

@screen md {
	.added-to-wishlist-content {
		.added-to-wishlist {
			grid-template-columns: 1.8fr 1fr;
		}

		.added-to-wishlist-right-column {
			@apply tw-border-l tw-border-solid tw-border-gray-400;
			padding-left: 19px;
			padding-top: 0px;
		}

		.room-assignment-form {
			margin-top: 13px;
			padding-top: 23px;
		}
	}
}

.media-main-counter {
	@apply tw-absolute tw-bg-gray-200 tw-duration-300 tw-delay-500 tw-flex tw-m-2 tw-opacity-100 tw-px-2 tw-right-0 tw-self-end tw-text-xs tw-text-white tw-top-0 tw-transition-opacity;
}

.media-main-counter-selected {
	@apply tw-delay-2500 tw-opacity-0;
}

@screen md {
	.media-main-counter {
		@apply tw-mr-12;
	}
}

@media screen {
	.shop-this-room-media, .media-image-container {
		position: relative;
	}

	.media-buttons {
		position: absolute;
		bottom: 10px;
		z-index: 2;
		left: 10px;
		display: flex;
	}

	.shop-this-room-link {
		margin: 0 8px;
		height: 34px;
		width: 34px;
		padding: 5px 0;
		width: auto;

		svg {
			width: 26px;
			margin-left: 10px;
		}

		.shop-this-room-link-text {
			margin: 0 10px;
		}
	}
}

.selector-options-inserts-expanded {
	.selector-options-inserts-selectors {
		@apply tw-overflow-visible;
		max-height: none;
	}
}

.selector-options-inserts-selectors {
	@apply tw-overflow-hidden;
	margin: 0 0 0 20px;
	max-height: 0;
}

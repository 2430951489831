.media-thumbnail {
	@apply tw-border tw-border-solid tw-border-transparent tw-h-full tw-mx-2 tw-p-1;
	border-bottom-width: 3px;

	img {
		@apply tw-h-auto tw-w-auto;
		max-height: 45px;
	}


	&.slider-with-nav-thumbnail {
		img {
			max-height: 100px !important;
		}
	}

	&:focus, &:hover {
		@apply tw-outline-dotted tw-outline-1 tw-outline-gray-300;
		outline-offset: 2px;
	}

	&:global.dot:hover {
		@apply tw-outline-none;
	}
}

.media-thumbnail-selected {
	@apply tw-border-gray;
}



@media screen {
	.curalate-detail-container {
		.curalate-detail-nav {
			cursor: pointer;
			margin: auto;
			padding: 4px;
			position: absolute;
			top: 275px;

			&.curalate-detail-nav-disabled {
				opacity: 0.5;
			}

			&.curalate-detail-nav-prev {
				left: -55px;

				&:focus, &:hover {
					&:before {
						border-right-color: theme('colors.orange.DEFAULT');
					}
				}
			}

			&.curalate-detail-nav-next {
				right: -55px;

				&:focus, &:hover {
					&:before {
						border-left-color: theme('colors.orange.DEFAULT');
					}
				}
			}
		}

		.curalate-detail-item {
			@apply tw-flex tw-flex-row tw-w-full;
		}

		.curalate-detail-item-image-report-container {
			@apply tw-flex tw-flex-col;
			flex: 0 0 450px;

			.curalate-detail-item-image {
				@apply tw-flex tw-flex-col tw-flex-nowrap tw-justify-center tw-items-center;
				flex: 0 1 325px;

				.curalate-item-image {
					flex: 0 0 auto;
				}
			}

			.curalate-detail-item-report {
				@apply tw-self-start;
				font-size: 14px;
				margin: 5px 0 0;
			}
		}

		.curalate-item-source-products-container {
			@apply tw-flex tw-flex-col;
			flex: 1 1 auto;
			margin: 0 0 0 20px;
		}

		.curalate-item-source {
			@apply tw-flex tw-flex-row tw-flex-wrap tw-justify-start;
			margin: 0 0 10px;

			.curalate-item-source-user {
				@apply tw-flex tw-flex-row tw-items-center;
				flex: 1 0 100%;
				margin: 0 0 15px;

				.curalate-item-source-user-image {
					margin: 0 15px 0 0;
					width: 75px;
				}

				.curalate-item-source-user-details {
					@apply tw-flex tw-flex-col;
				}
			}

			.curalate-item-source-caption {
				flex: 1 0 100%;
				font-size: 14px;
				word-wrap: break-word;
				overflow-wrap: anywhere;
			}
		}

		.curalate-item-products-container {
			.curalate-item-products-heading {
				@apply tw-text-navy;
				font-size: 24px;
				margin: 0 0 15px;

				span {
					font-weight: 300;
				}
			}

			.curalate-item-products {
				@apply tw-flex tw-flex-wrap tw-justify-start tw-m-0;

				.curalate-item-product {
					@apply tw-flex tw-flex-col;
					flex-basis: calc(50% - 20px);
					margin: 10px;

					.curalate-item-product-image-container {
						@apply tw-flex tw-flex-col tw-flex-nowrap tw-justify-center tw-items-center;
						flex: 0 0 110px;
						// yes, we need to set height instead fo flex-basis here so IE will respect the max-height on the child element
						height: 110px;

						.curalate-item-product-image {
							max-height: 100%;
						}
					}

					.curalate-item-product-description {
						text-align: center;
					}
				}
			}
		}
	}

	@screen smOnly {
		.curalate-detail-container {
			margin-top: -10px;
			position: relative;

			.curalate-detail-nav {
				padding: 0;
				top: calc(50vw - 32px);
				z-index: 1;

				&:before {
					border-width: 22px 0;
				}

				&.curalate-detail-nav-prev {
					left: 2px;
				}

				&.curalate-detail-nav-next {
					right: 2px;
				}
			}

			.curalate-detail-item {
				@apply tw-flex tw-flex-col;

				.curalate-detail-item-image-report-container {
					flex-basis: auto;
					margin: 0 0 20px;

					.curalate-detail-item-report {
						font-size: 15px;
						@apply tw-m-0;
					}
				}
			}

			.curalate-item-source-products-container {
				@apply tw-flex tw-flex-col;
				@apply tw-m-0;
			}
		}
	}
}

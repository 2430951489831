@media screen {
	.selector-details-container {
		@apply tw-mt-0 tw-mx-0 tw-mb-4;
		display: flex;
		flex-direction: row;
		.detail-image {
			@apply tw-mt-0 tw-mr-4 tw-mb-0 tw-ml-0 tw-relative tw-flex-none tw-flex;

			img {
				flex: 1 1 auto;
				max-height: 120px;
				max-width: 150px;
				overflow: hidden;
				object-fit: cover;
			}
		}
	}

	.selector-details {
		@apply tw-m-0 tw-text-start;
	}
}

@screen md {
	.selector-details-container {
		.detail-image {
			width: 45%;
			display: block;
			height: fit-content;
			img {
				max-width: 252px;
				max-height: 130px;
				object-fit: fill;
			}
		}
	}
}

@screen lg {
	.selector-details-container {
		.detail-image {
			width: 50%;
		}
	}
}

.selector-options-type {
	@apply tw-flex tw-flex-col tw-items-start tw-mb-4;
}

.selector-options-type-title {
	@apply tw-font-bold tw-mb-1;
}

.selector-options-type-description {
	@apply tw-mb-2;
}

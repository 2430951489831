//*************************
//** Widened Content Container
//** breaks the content out of the usual width of the site
//*************************
.widenedContentContainer {
    @screen md {
        @apply tw-full-screen-container tw-px-3;
        .widenedContent {
            @apply tw-full-screen-content;
            max-width: 1600px;
        }
    }
}

.ShopThisRoomHeader {
    @apply md:tw-flex tw-justify-between md:tw-pb-8 tw-flex-col tw-space-y-2;
}

.ShopThisRoomPrevNext {
    @apply tw-hidden;
    @screen md {
        @apply tw-divide-x tw-block tw-text-right;
        a {
            @apply tw-font-bold tw-no-underline hover:tw-underline tw-px-3;
        }
        a:last-of-type {
            @apply tw-pr-0;
        }
    }
}

.ShopThisRoomHero {
    display: block;
    img {
        max-height: 268px;
        width: auto;
    }
    @screen md {
        flex: 0 0 50%;
        width: 50%;
        img {
            max-height: 700px;
            width: auto;
        }
    }
}

.ShopThisRoomProducts {
    @apply tw-float-left;
    li {
        @apply tw-px-2 tw-inline-block tw-mb-12;
        width: 50%;
        height: unset;
        @screen md {
            width: 25%;
        }
    }
    &.ShopThisRoomTray {
        li {
            @apply tw-px-0;
            width: 50%;
        }
    }
    a {
        display: block;
        text-decoration: none;
        color: #333;
        padding: 0 6px 6px 6px;
    }
}

.ShopThisRoomProduct {
    position: relative;
    .product-group-fav-btn {
        right: 6px;
        top: 0;
        @apply tw-absolute tw-bg-transparent;
    }
}


.ProductImageWrapper {
    @apply tw-flex tw-items-end tw-justify-center tw-h-[140px] md:tw-h-[200px];
    img {
        max-height: 140px;
        width: auto;
    }
    @screen md {
        height: 260px;
        img {
            max-height: 260px;
        }
    }
}

.TrayProductImageWrapper {
    @apply tw-flex tw-items-end tw-justify-center tw-h-[182px];
    img {
        max-height: 182px;
        width: auto;
    }
}

.ProductDetails {
    @apply tw-flex tw-flex-col tw-relative tw-mt-2 tw-leading-6;
}

.ProductName {
    @apply tw-font-bold tw-leading-5 tw-mb-2;
    font-size: 17px;
    @screen md {
        @apply tw-text-base tw-leading-7 tw-mb-0;
    }
}

.ProductPrice, .ProductOptions{
    @apply tw-text-base md:tw-text-xs tw-leading-5 tw-mb-2;
    @screen md {
        @apply tw-leading-6 tw-mb-0;
    }
}

.ProductStatus {
    @apply tw-text-orange tw-text-4xs tw-leading-5;
}

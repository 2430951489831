// specific media sizing for MediaSetDetail
.media-set-detail {
	@apply tw-mb-5;

	:global .slick-slide > div > * {
		height: 250px;
	}

	:global .slick-slide button img {
		max-height: 250px;
		@apply tw-h-auto tw-w-auto;
	}
}

.media-set-detail-fullscreen {
	:global .slick-slide .show-navigation,
	:global .cylindo-wrapper,
	:global .media-cylindo-dimensions-image {
		height: 650px !important;
		max-height: calc(100vh - 190px);
	}

	:global .slick-slide > div > * img {
		@apply tw-h-auto tw-max-h-full tw-w-auto;

		&.show-drift {
			@screen md {
				pointer-events: auto; // to show drift-zoom zoomed in panel the image needs to have pointer events enabled
				&:active, &:focus {
					pointer-events: none; // disables pointer events while interacting with image (panning, zooming, etc)
				}
			}
		}
	}

	:global .cylindo-wrapper {
		@apply tw-mb-5;
	}

	:global .media-cylindo-dimensions-image {
		min-height: 420px;
	}
}

@screen md {
	.media-set-detail {
		@apply tw-mb-16;

		:global .slick-slide > div > *,
		:global .cylindo-wrapper {
			height: 400px !important;
		}

		:global .slick-slide img {
			max-height: 400px !important;
		}

		:global .cylindo-wrapper {
			@apply tw-mb-5;
		}
	}

	.media-set-detail-fullscreen {
		:global .react-transform-wrapper, :global .react-transform-component {
			@apply tw-h-full;
		}
	}
}

.AddToFavoritesHeart {
	@apply tw-z-[5] tw-border-0 tw-inline-flex tw-bg-transparent;

	&:focus, &.focus {
		@apply tw-bg-transparent;
		outline: 1px dotted theme('colors.gray.300');
		outline-offset: 2px;
		filter: brightness(0.2);
		box-shadow: 0 0 1px theme('colors.gray.400') !important;
	}

	/* smartphones, touchscreens */
	@media (hover: none) and (pointer: coarse) {
		&:hover .heart {
			@apply tw-bg-transparent;
			animation: brightnessAnimation 200ms;
		}
	}

	/* mouse, touch pad */
	@media (hover: hover) and (pointer: fine) {
		&:hover .heart {
			@apply tw-bg-transparent;
			filter: brightness(0.2);
		}
	}

	&:global(.js-button-flasher) {
		&::before {
			@apply tw-bg-transparent;
		}
	}
}

:global(.is-animating) {
	.heart {
		display: none;
	}
	.heartChecked {
		@apply tw-inline;
	}
}

:global(.is-animating-out) {
	.heart {
		display: inline;
	}
	.heartChecked {
		display: none;
	}
}

@keyframes brightnessAnimation {
    0% {
        filter: brightness(0.2);
    }
    100% {
		filter: brightness(1);
    }
}
.workspace {
	@apply tw-flex tw-flex-col;
}

.workspace-products-container {
	@apply tw-flex tw-flex-col tw-items-center tw-justify-center;
	margin: 20px 0;
	min-height: 200px;

	@screen md {
		margin: 55px 20px 26px 0;
		min-height: 400px !important;
	}

	@screen lg {
		min-height: 480px !important;
	}

	&.workspace-products-container-show-controls,
	&.workspace-products-container-show-dimensions {
		margin: 55px 0 26px;
		min-height: 225px;

		@screen md {
			margin: 55px 20px 26px 0;
		}
	}

	&.workspace-product-container-show-frame-and-overall-dimensions {
		margin-top: 60px;

		:global .workspace-products-width {
			top: -72px;
		}

		@screen md {
			margin-top: 85px;
		}
	}
}

.workspace-products-controls-groups {
	@apply tw-justify-around;
}
.workspace-product-controls {
	@apply tw-my-3;
}

.workspace-product-control {
	&:not(:last-of-type) {
		@apply tw-border-r tw-border-solid;
		border-right-color: theme('colors.gray.300');
		padding-right: 14px;
		margin-right: 14px;
	}
}

.workspace-modal-frame {
	@apply tw-flex tw-flex-col tw-items-center tw-mb-3;
}

.workspace-modal-frame-product {
	@apply tw-flex tw-mb-5;
}

.workspace-modal-frame-product-dimensions {
	@apply tw-mt-8;
}

.workspace-modal-frame-product-image {
	@apply tw-relative;
}

.workspace-modal-frame-control {
	@apply tw-mb-5;
}

.workspace-product-insert-options-edit {
	@apply tw-absolute tw-flex tw-h-full tw-left-0 tw-p-1 tw-pointer-events-auto tw-top-0 tw-w-full;

	&:focus-within {
		.workspace-product-insert-options-edit-button {
			@apply tw-opacity-100;
		}
	}

	&:hover {
		background-color: rgba($color: #fff, $alpha: 0.85);

		.workspace-product-insert-options-edit-button {
			@apply tw-opacity-100 tw-text-blue;

			&:disabled {
				@apply tw-text-gray;
			}
		}

		~ * {
			@apply tw-hidden;
		}
	}
}

.workspace-product-insert-options-edit-button {
	@apply tw-grow tw-opacity-0 tw-text-transparent tw-text-xs tw-underline;

	&:disabled {
		@apply tw-no-underline;
	}

	@screen md {
		@apply tw-text-base;
	}
}

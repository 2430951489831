@media screen {
	.selector-medium-image-container {
		left: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
		max-height: none;
		overflow-x: auto;
		overflow-y: visible;
		position: relative;
		right: 50%;
		width: 100vw; 
		@screen md {
			left: unset;
			margin: 0;
			max-height: 400px;
			overflow-y: auto;
			position: unset;
			right: unset;
			width: unset;
		}
	}

	.selector-medium-image-shadow:after {
		background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1));
		bottom: 0;
		content: ' ';
		display: block;
		height: 5px;
		left: 0;
		opacity: .1;
		position: absolute;
		width: 100%;
		z-index: 1;
	}

	.selector-values-types-medium-image {
		display: flex;
		flex-direction: row;
		padding: 5px 5px 15px;

		&:after {
			content: ' ';
			padding: 2.5px;
			@screen md {
				content: unset;
				padding: 0;
			}
		}

		@screen md {
			flex-direction: column;
			padding: 0;
		}
	}

	.selector-values-type-medium-image {
		align-items: flex-start;
		flex: 0 0 auto;
		margin: 0 15px 0 0;
		display: flex;
		flex-direction: column;
		
		&:last-child {
			margin-right: 0;
		}

		@screen md {
			align-items: unset;
			flex: unset;
			margin: 0 0 10px;
			flex-basis: auto !important;
			
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.selector-values-type-medium-image-title  {
		background-color: theme('colors.white.DEFAULT');
		font-weight: bold;
		left: 0;
		margin: 0 0 5px -5px;
		padding: 0 0 5px 5px;
		position: sticky;
		top: 0;
		z-index: 1;
	}

	.selector-values-medium-image {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: baseline;
		padding: 0;

		&:hover {
			.selector-value-medium-image {
				outline: none;
			}
		}

		@screen md {
			flex-wrap: wrap;
			padding: 0 0 10px 5px;
		}
	}

	.selector-value-medium-image {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		flex: 1 1 calc(33% - 10px);
		max-width: 114px;
		border: 1px solid transparent;
		color: theme('colors.gray.DEFAULT');
		font-weight: normal;
		line-height: 1.2;
		margin: 0 14px 0 0;
		outline-offset: 2px;
		padding: 3px 3px 5px;
		position: relative;
		text-align: left;
		text-decoration: none;

		&:focus-within {
			box-shadow: none !important;
			color: theme('colors.gray.DEFAULT');
			outline: 1px dotted theme('colors.gray.300');
		}

		&:hover {
			color: theme('colors.gray.DEFAULT');
			outline: 1px dotted theme('colors.gray.300') !important;

		}

		@screen md {
			justify-content: unset;
			margin: 3px 8px 10px 0;
		}

		@screen lg {
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}

	.selector-value-medium-image-image {
		margin: 0 0 5px;
	}

	.selector-value-medium-image-text {
		display: flex;
		flex-direction: column;
	}

	.selector-value-medium-image-title {
		font-size: 12px;
	}

	.selector-value-medium-image-selected {
		&, &:focus, &:hover {
			border: 1px solid theme('colors.gray.DEFAULT');
			border-bottom: 2px solid theme('colors.gray.DEFAULT');
			padding-bottom: 4px;
		}

		.selector-value-medium-image-title {
			font-weight: bold;
		}
	}
}

.workspace-product {
	@apply tw-flex tw-flex-col tw-items-center tw-relative;
	~ .workspace-product {
		:global .workspace-product-width {
			border-left: none !important;
		}
	}
}

.workspace-product-draggable-container {
	@apply tw-relative;
}

.workspace-products-message-container {
	@apply tw-absolute tw-flex tw-h-full tw-items-center tw-justify-center tw-w-full;
}

.workspace-products-message {
	@apply tw-relative;
	min-width: 300px;
	z-index: 99;
}

.workspace-products-message-close {
	@apply tw-absolute;

	height: 24px;
	right: 8px;
	top: 8px;
	width: 24px;

	&:before, &:after {
		@apply tw-block tw-border-gray tw-border-solid tw-border-t;
		content: '';
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}

.workspace-products-message-text {
	@apply tw-border tw-border-gray tw-border-solid tw-px-3 tw-py-10 tw-text-center;
	background-color: rgba(255, 255, 255, 0.95);

	@screen md {
		@apply tw-px-10;
	}
}

.media-dots {
	:global .magic-dots ul {
		@apply tw-transition-transform tw-duration-200 tw-flex tw-my-2 tw-py-1;

		li {
			@apply tw-flex;

			button {
				@apply tw-border tw-border-solid tw-border-transparent tw-flex tw-items-center tw-justify-center tw-p-0 tw-rounded-full;
				height: 18px;
				margin: 0 6px;
				width: 18px;
	
				&:before {
					@apply tw-bg-gray-400 tw-shrink-0 tw-rounded-full;
					content: '';
					height: 12px;
					width: 12px;
				}

				&:focus, &:hover {
					&:before {
						@apply tw-bg-gray-300;
					}
				}
			}

			&.slick-active button {
				@apply tw-bg-white tw-border tw-border-gray tw-border-solid;

				&:before {
					@apply tw-bg-gray-300;
				}
			}

			&.small button:before {
				height: 6px;
				width: 6px;
			}
		}
	}

	img, [data-workspace-products-container] {
		display: none;
	}
}

.workspace-product-insert {
	@apply tw-absolute tw-bottom-0 tw-h-full tw-left-0 tw-w-full;
}

.workspace-product-insert-DOUBLE_TALL {
	height: 200%;
}

.workspace-product-insert-DOUBLE_WIDE {
	width: 200%;
}

.workspace-product-insert-QUAD {
	height: 200%;
	width: 200%;
}

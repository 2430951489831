@media screen {
    .four-square-recs {
        @apply tw-border-gray-400;
        border-top: solid 1px;
        padding-top: 21px;

        .rec-items {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
            row-gap: 20px;

            :global .RecoItem.RecoItem--lg {
                width: 100%;
                padding: 0;
            }
        }
    }
}

.media-control-fullscreen {
	@apply tw-rounded-full;
	height: 42px;
	padding: 3px;
	width: 42px;

	&:hover, &:focus {
		@apply tw-border tw-border-solid tw-border-gray tw-bg-gray;

		.media-control-fullscreen-image-container {
			@apply tw-bg-gray;
		}

		.media-control-fullscreen-image {
			filter: invert(1)
		}
	}
}

.media-control-fullscreen-image-container {
	@apply tw-flex tw-items-center tw-h-full tw-rounded-full tw-justify-center tw-w-full;
}

.workspace-product-insert-options-saved {
	@apply tw-absolute tw-h-full tw-left-0 tw-top-0 tw-w-full;
}

.workspace-product-insert-options-saved-check {
	@apply tw-absolute tw-flex tw-items-center tw-justify-center tw-relative tw-rounded-full;
	background-color: rgba(255, 255, 255, 0.95);
	height: 30px;
	left: calc(50% - 15px);
	top: calc(50% - 15px);
	width: 30px;

	@screen md {
		height: 40px;
		left: calc(50% - 20px);
		top: calc(50% - 20px);
		width: 40px;
	}

	&:before {
		@apply tw-border-b tw-border-l;
		content: '';
		height: 7.5px;
		transform: rotate(-45deg) translateX(1.5px) translateY(-1.5px);
		width: 20px;

		@screen md {
			height: 10px;
			transform: rotate(-45deg) translateX(2px) translateY(-2px);
			width: 25px;
		}
	}
}

.media-link-shop-this-room {
	@apply tw-absolute tw-bottom-0 tw-left-0 tw-m-2;
}

.media-link-shop-this-room-image {
	height: 25px !important;
	margin: 4px;
	width: 25px !important;
}

.media-link-shop-this-room-text {
	@apply tw-ml-1 tw-mr-3;
}

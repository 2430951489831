.workspace-modal-insert-options-control-checkbox {
	@apply tw-flex tw-mb-5;
}

.workspace-modal-insert-options-control-checkbox-label {
	@apply tw-flex tw-items-baseline;
}

.workspace-modal-insert-options-control-checkbox-input {
	@apply tw-shrink-0 tw-mr-2;
}

.workspace-modal-insert-options-control-checkbox-text {
	@apply tw-font-normal;
}

.workspace-modal-insert-options-control-checkbox-warning {
	@apply tw-block tw-text-red;
}

.workspace-modal-insert-options-control-vertical-radio {
	@apply tw-flex tw-flex-col tw-items-start tw-mb-5 tw-pb-5;

	&:not(:last-child) {
		@apply tw-border-b tw-border-gray-400 tw-border-solid;
	}
}

.workspace-modal-insert-options-control-vertical-radio-label {
	@apply tw-flex tw-items-center tw-mb-2;

	&:last-child {
		@apply tw-mb-0;
	}
}

.workspace-modal-insert-options-control-vertical-radio-input {
	@apply tw-shrink-0 tw-mr-2;
}

.workspace-modal-insert-options-control-vertical-radio-text {
	@apply tw-font-normal;
}

.selector-options-toggle {
	@apply tw-font-bold tw-mb-3 tw-no-underline;
	padding: 0 0 0 20px;

	&:before {
		@apply tw-absolute;
		border-left: 8px solid theme('colors.blue.DEFAULT');
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;
		content: '';
		left: 2px;
		transition: transform .2s ease;
	}

	&:focus, &:hover {
		@apply tw-text-blue tw-underline;
	}
}

.selector-options-toggle-expanded:before {
	transform: rotate(90deg);
}

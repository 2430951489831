.workspace-control-help {
	@apply tw-absolute tw-no-underline tw-right-0;
	&:before {
		@apply tw-border tw-border-blue tw-border-solid;
		border-radius: 50%;
		content: '?';
		height: 30px;
		line-height: 30px;
		margin: 0 5px 0 0;
		width: 30px;
	}

	@screen md {
		@apply tw-flex-col tw-left-0 tw-right-auto;
		&:before {
			height: 20px;
			line-height: 20px;
			width: 20px;
		}
	}
}

.workspace-control-help-text {
	@apply tw-underline;
}

.workspace-optional-features-saved {
	@apply tw-delay-2500 tw-duration-500 tw-flex tw-mb-5 tw-items-center tw-justify-center tw-opacity-0 tw-text-base tw-transition;

	&:before {
		@apply tw-border-b tw-border-l tw-mr-3;
		content: '';
		height: 7px;
		transform: rotate(-45deg) translateX(2px);
		width: 15px;
	}
}

.workspace-optional-features-saved-animation {
	@apply tw-delay-0 tw-opacity-100;
}

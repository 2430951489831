.workspace-modal-insert-options-image {
	@apply tw-flex tw-justify-center tw-mb-5;
	:global {
		img {
			@apply tw-h-auto tw-w-auto;
		}
	}
}

.workspace-modal-insert-options-title-container {
	@apply tw-mb-5;
}

.workspace-modal-insert-options-title {
	@apply tw-font-bold;
}

.workspace-modal-insert-options-subtitle {
	@apply tw-text-gray-300;
}


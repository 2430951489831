.workspace-products-width {
	@apply tw-absolute tw-text-center tw-w-full;
	top: -65px;
}

.workspace-products-width-line-container {
	@apply tw-border-gray tw-border-l tw-border-r tw-border-solid;
	padding-top: 4px;
	padding-bottom: 4px;
}

.workspace-products-width-line {
	@apply tw-bg-gray tw-border-b tw-border-gray;
	height: 1px;
}

.workspace-products-width-value {
	@apply tw-relative tw-text-center tw-whitespace-nowrap;
	@apply tw-bg-white #{!important};
	top: 12px;
}

.workspace-products-height {
	@apply tw-flex tw-items-center;
	margin-right: 8px;
}

.workspace-products-height-line-container {
	@apply tw-border-b tw-border-gray tw-border-solid tw-border-t tw-flex tw-h-full tw-justify-center tw-left-1/2 tw-relative;
	width: 10px;
}

.workspace-products-height-line {
	@apply tw-bg-gray tw-border-gray tw-border-l tw-border-solid tw-h-full;
	width: 1px;
}

.workspace-products-height-value {
	@apply tw-whitespace-nowrap tw-z-10;
	@apply tw-bg-white #{!important};
}

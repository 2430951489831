.CartInterstitial {
	.CartInterstitial-productImgWrapper {
		padding: 0 15px;
	}
	.CartInterstitial-productImg {
		@apply tw-block tw-m-auto;
		max-height: 256px;
	}
	.CartInterstitial-productName {
		@apply tw-font-semibold tw-mb-0;
		font-size: 17px;
	}
	.CartInterstitial-summary {
		@apply tw-border-t-2 tw-border-solid tw-border-gray-400 tw-pt-6 tw-pb-4;
	}
	.CartInterstitial-lineItemMessage {
		@apply tw-block tw-bg-gray-500 tw-border tw-border-solid tw-border-gray-400 tw-pt-6 tw-pb-4;
		padding: 3px 6px 6px;

		p:last-child {
			margin-bottom: 0;
		}
	}
}

@screen md {
	.CartInterstitial {
		.CartInterstitial-summary {
			@apply tw-w-4/12 tw-border-t-0 tw-border-l-2 tw-pl-6;
		}
	}
}

@media screen {
	.details-attributes {
		.detail {
			max-width: 100%;
		}
	}
}

.details-attributes-column {
	&:not(:first-child) {
		@apply tw-mt-5;
	}
}
@screen md {
	.details-attributes-column {
		&:not(:first-child) {
			@apply tw-mt-0;
		}
	}
}

.selector-heading {
	@apply tw-mb-3;

	:global .detail-link {
		top: auto;
	}
}

.selector-heading-label {
	font-size: 16px;
	font-weight: bold;
	margin: 0 10px 0 0;

	@screen md {
		font-size: 15px;
	}
}

.selector-heading-title {
	@apply tw-mr-3;
	font-size: 16px;
	font-weight: normal;

	@screen md {
		font-size: 15px;
	}
}

@import 'css/lib/tailwind-custom/_heading';

.details-materials-list {
	&> .details-list {
		@screen print {
			@apply tw-flex-col #{!important};
		}

		:global .detail-container {
			@apply tw-mt-0 tw-mx-0 tw-mb-5;

			@screen print {
				@apply tw-mb-0 #{!important};
			}

			.details-list {
				:global .detail-container {
					@apply tw-mt-0 tw-mx-0 tw-mb-2;
					flex-basis: auto;
				}
			}
		}
	}

	.details-list {
		@apply tw-flex tw-flex-col tw-m-0 tw-p-0;
		width: 100%;
	}

	.detail-question {
		display: flex;
		flex-direction: column;
		margin: 0 0 15px;
	}

	.detail-name-value-group {
		display: flex;
		flex-direction: column;
		margin: 0 0 15px;
	}

	.detail-image {
		margin: 0 0 10px;
		flex: 0 0 auto;
		display: flex;
		height: fit-content;

		img {
			flex: 1 1 auto;
			max-height: 120px;
			overflow: hidden;
			object-fit: cover;
		}
		
		@screen print {
			@apply tw-hidden;
		}
	}
}

@screen md {
	.details-materials-list {
		&> .details-list {
			@apply tw-flex tw-flex-row tw-flex-wrap tw-justify-between;
		}

		:global .detail-container {
			flex: 0 0 calc(50% - 10px);
		}

		.detail-question {
			display: flex;
			flex-direction: row;
			margin: 0;
		}

		.detail-name-value-group {
			display: flex;
			flex-direction: row;
			margin: 0;
		}

		.detail-image {
			margin: 0 15px 0 0;
			display: block;
			width: 37%;

			img {
				flex: unset;
				max-height: 130px;
				max-width: 252px;
				overflow: unset;
				object-fit: fill;
			}
		}
	}
}


@screen lg {
	.details-materials-list {
		.detail-image {
			width: 33%;
		}
	}
}

.selector-swatch-container {
	left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	overflow-x: auto;
	overflow-y: visible;
	position: relative;
	right: 50%;
	width: 100vw;

	@screen md {
		margin-left: 0;
		margin-right: 0;
		overflow-x: visible;
		overflow-y: visible;
		position: unset;
		width: unset;
	}
}


.ShopThisRoomTray {
	:global .MagicModal {
		@screen bs {
			width: calc(33vw + 20px) !important;  // add 20px for scrollbar
		}
		@screen smBsOnly {
			width: 320px !important; // add 20px for scrollbar
		}
		width: 100vw;
		max-width: unset;
	}
}
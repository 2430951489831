.mainGrid {
	@apply tw-grid tw-gap-3;
	grid-template-columns: 1fr;

	@screen md {
		grid-template-columns: 1fr 4px 1fr;

		&.multipleItems {
			grid-template-columns: 1.1fr 4px .9fr;

			.item {
				grid-template-columns: 185px auto;
			}
		}

		&.engageGrid {
			grid-template-columns: 1fr;
		}
	}
}

.item {
	@apply tw-grid tw-gap-4 tw-grid-cols-2 tw-content-start tw-no-underline tw-text-gray-100;
	grid-template-columns: 100px auto;
	margin-bottom: 12px;

	@screen md {
		grid-template-columns: 220px auto;
		margin-bottom: 15px;
	}
}

.itemInfo {
	@apply tw-self-center;
	line-height: 21px;
}

.verbiageContainer {
	@apply tw-p-3 tw-border tw-border-gray-400;

	@screen md {
		@apply tw-mt-6 tw-border-none;
	}
}

.verbiageContainerSimple {
	@apply tw-mt-6 tw-p-3 tw-border tw-border-gray-400 tw-bg-gray-600;
}

.verbiage {
	@apply tw-mt-3;
}

.imageHolder {
	@apply tw-flex tw-justify-center tw-items-center tw-overflow-hidden;
	height: 200px;

	@screen md {
		height: 200px;
	}

	img {
		object-fit: contain;
		height: 100%;
		max-height: 99%;
		max-width: 99%;
	}
}

.name {
	@apply tw-font-semibold;
	margin-bottom: 2px;
}

.list-name {
	@apply tw-break-words tw-line-clamp-1;

	&::first-letter {
		@apply tw-capitalize;
	}
}

.buttons {
	@apply tw-mt-7 tw-grid tw-gap-6 tw-justify-start tw-grid-flow-row;

	button {
		@apply tw-justify-start;
	}

	@screen md {
		@apply tw-grid-flow-col;
	}
}

.list-of-lists {
	@apply tw-mb-6
}

$nav-spacing: 40px;

.media-set {
	:global .slick-track {
		@apply tw-flex tw-items-center tw-mb-1;
	}
}

.media-set-override {
	:global .slick-track {
		display: none !important;
	}
}

.media-set-sliding-navigation {
	:global .slick-list {
		margin: 0 $nav-spacing;
	}
	:global .slick-track {
		justify-content: flex-end;
	}
}

.media-counter{
	@apply tw-mt-8;
	left: 0;
	right: unset;
	z-index: 1;
}

@screen md {
	.media-set {
		:global .slick-track {
			@apply tw-mb-5;

			button[class*="media-main-button"] {
				margin: 3px 0 0;
				max-width: calc(100% - 6px);
			}
		}
	}
}

@screen print {
	.media-set {
		:global .slick-track {
			width: unset !important;
			transform: unset !important;
			margin-bottom: 0 !important;
		}
		:global .slick-slide {
			display: none;
			&.slick-current {
				display: block;
			}
		}
	}
}

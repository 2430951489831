@import 'css/lib/tailwind-custom/_heading';

.details:not(.details-expanded) {
	:global .product-row:not(:first-child) {
		@apply tw-m-0 tw-p-0;
		border-bottom: none;
		display: none;
		max-height: 0;
		overflow: hidden;

		@screen print {
			@apply tw-flex tw-max-h-full tw-mb-3;
			flex-basis: 50%;
		}
	}
}

.details {
	@screen print {
		@apply tw-flex tw-flex-wrap;

		:global .columns {
			@apply tw-flex-auto tw-max-w-none;
		}
	}

	&.details-expanded {
		:global .details-toggle {
			&:before {
				transform: rotate(90deg);
			}
		}
	
		:global .product-row:last-child {
			border-bottom: none;
		}
	}

	:global .details-toggle {
		@apply tw-text-lg tw-py-0 tw-pr-0 tw-pl-5;
		text-decoration: none !important;

		&:before {
			border-left: 8px solid theme('colors.blue.DEFAULT');
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			content: '';
			height: 0;
			left: 2px;
			position: absolute;
			transition: transform .2s ease;
			width: 0;
		}

		&:focus, &:hover {
			@apply tw-text-blue #{'!important'};
			text-decoration: underline !important;
		}
	}

	:global .detail-container {
		@apply tw-mt-0 tw-mx-0 tw-mb-2;

		@screen print {
			@apply tw-mb-0;
		}
	}

	:global .details-list {
		@apply tw-m-0 tw-p-0;
		& + .details-list {
			@apply tw-mt-4;
		}
	}

	:global .detail-link {
		@apply tw-text-base;
		vertical-align: baseline;

		.detail-link-icon {
			margin-left: 10px;
		}
	}
}

.details-toggle-container {
	padding: 15px 0;
}

.details-title {
	@include Heading;
	@include Heading--4;
	@apply tw-text-lg tw-font-normal tw-text-gray tw-mt-0 tw-mx-0 tw-mb-2;

	@screen md {
		@apply tw-text-xl tw-font-light;
	}

	@screen print { 
		@apply tw-font-normal tw-mb-1 tw-text-base;
	}
}

.detail {
	@apply tw-max-w-full tw-text-base;
}

@screen md {
	.details {
		:global .detail-link {
			@apply tw-text-sm;
		}
	}

	.detail {
		@apply tw-text-sm;
	}
}

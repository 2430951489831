.thumbnail {
	@apply tw-relative tw-w-full;
}

.thumbnailDuration {
	@apply tw-absolute tw-bg-gray tw-bottom-0 tw-m-0 tw-px-2 tw-py-1 tw-right-0 tw-text-white;

	font-size: 11px;
	line-height: 11px;
	padding: 1px 3px;
}

.showcaseVideoDuration {
	@apply tw-text-xs tw-bg-gray tw-text-white tw-pl-1 tw-pr-1 tw-font-semibold tw-absolute tw-bottom-0 tw-right-0 tw-m-1 md:tw-text-sm;
}

.playButton {
	@apply tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-z-10;

	button {
		@apply tw-bg-black tw-h-[45px] tw-w-[70px] tw-relative tw-border-0;
		&:hover, &:focus {
			background-color: #1b1b1b;
		}
		.triangle {
				width: 14px;
				height: 20px;
				border-left: 0;
				border-right: 0;
				border-top: 10px solid transparent;
				border-bottom: 10px solid transparent;
				border-left: 14px solid white;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(calc(-50% + 2px), -50%);
		  }
	}

	@screen md {
		button {
			@apply tw-h-[80px] tw-w-[125px];
			.triangle {
					width: 36px;
					height: 26px;
					border-top: 18px solid transparent;
					border-bottom: 18px solid transparent;
					border-left: 26px solid white;
					transform: translate(calc(-50% + 7px), -50%);
			  }
		}
	}
	@screen bs {
		button {
			@apply tw-h-[92px] tw-w-[145px];
			.triangle {
					width: 30px;
					height: 41px;
					border-top: 20.5px solid transparent;
					border-bottom: 20.5px solid transparent;
					border-left: 30px solid white;
					transform: translate(calc(-50% + 7px), -50%);
			  }
		}
	}
}
// need the media-nav-container rule to achieve greater specificicity when competing with .tw-not-sr-only
.media-nav-container {
	.media-nav {
		@apply tw-bg-white tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bottom-1/2 tw-z-10;
		// !important position: absolute rule to override .tw-not-sr-only which sets position: static and auto width/height
		@apply tw-absolute #{!important};
		height: 35px !important;
		opacity: 0.9;
		width: 35px !important;

		&:before {
			@apply tw-block tw-border-gray tw-border-solid;
			content: '';
			height: 16px;
			width: 16px;
		}

		&:focus, &:hover {
			@apply tw-bg-gray tw-opacity-100;
			// !important position: absolute rule to override .tw-not-sr-only which sets position: static and auto width/height
			@apply tw-absolute #{!important};
			height: 35px !important;
			width: 35px !important;

			&:before {
				@apply tw-border-white;
			}
		}
	}

	&.slider-with-nav-thumbnail {
		.media-nav-next {
			right: 10px;
		}
		.media-nav-prev {
			left: 10px;
		}
	}

	&.subcategory {
		.media-nav {
			@apply tw-border-gray-100 tw-border;
			height: 24px !important;
			width: 24px !important;
			&:before {
				@apply tw-block tw-border-gray tw-border-solid;
				content: '';
				height: 9px;
				width: 9px;
			}

			&:focus, &:hover {
				&:before {
					@apply tw-border-white;
				}
			}
		}
		.media-nav-next {
			&:before {
				transform: rotate(-45deg) translateX(-1px) translateY(-1px) !important;
			}
		}

		.media-nav-prev {
			@apply tw-left-0;
		
			&:before {
				transform: rotate(45deg) translateX(2px) translateY(-1px) !important;
			}
		}
		
	}

	&.sustainability {
		.media-nav {
			@apply tw-bg-transparent tw-translate-y-1/2 focus:tw-bg-gray hover:tw-bg-gray;
		}
	}
}

.media-nav-next {
	@apply tw-right-0;

	&:before {
		transform: rotate(-45deg) translateX(-3px) translateY(-3px);
		@apply tw-border-b tw-border-r tw-transform tw--rotate-45 tw--translate-x-1;
	}
}

.media-nav-prev {
	@apply tw-left-0;

	&:before {
		transform: rotate(45deg) translateX(-3px) translateY(3px);
		@apply tw-border-b tw-border-l tw-transform tw-rotate-45 tw-translate-x-1;
	}
}

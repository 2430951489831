.RecoItem,
.RecoItem.RecoItem--tight {
	@apply tw-text-left tw-relative tw-no-underline tw-cursor-pointer;
	border: 1px solid transparent;
	flex: 0 0 224px;
	width: 224px;
	max-width: 224px;
	min-height: 210px;
	text-align: left;
	padding: 0 11px;

	&:focus {
		@apply tw-outline-none tw-border tw-border-dashed tw-border-gray-300;
		outline: none;
	}

	span {
		@apply tw-block;
	}
}

@media screen {
	.RecoItem {
		min-height: 185px;
		padding: 5px 8px;
		margin: 3px;

		&:hover {
			.RecoItem-title {
				@apply tw-underline;
			}
		}
		&:hover, &:focus, &:focus-within {
			@apply tw-border-transparent tw-text-gray #{'!important'};
		}
		&:focus, &:focus-within {
			@apply tw-outline-1 tw-outline-dotted tw-outline-gray-300 #{'!important'};
		}
	}
}

.RecoItem.RecoItem--lg {
	@apply tw-inline-block tw-min-h-0 tw-align-top;
	flex: 0 0 200px;
	width: 200px;
	max-width: 200px;

	.RecoItem-prodImage.RecoItem-prodImage.RecoItem-prodImage {
		margin: 0 auto 15px;
	}

	.RecoItem-review {
		@apply tw-flex tw-items-center;
	}

	.RecoItem-reviewImage.RecoItem-reviewImage.RecoItem-reviewImage.RecoItem-reviewImage {
		@apply tw-mr-1;
	}
}


.RecoItem-prodImage.RecoItem-prodImage.RecoItem-prodImage {
	@apply tw-block;
	margin: 0 auto 6px;
}

.RecoItem-title,
.RecoItem-price,
.RecoItem-review {
	@apply tw-text-gray tw-block;
	margin-bottom: 0.33rem;
	line-height: 1.3125rem;
	font-size: 0.9375rem;
}

.RecoItem-salePrice {
	margin-right: 6px;
	display: block;
}

.RecoItem-originalPrice {
	white-space: nowrap;
	display: block;
}

.RecoItem-title {
	font-weight: bold;
	font-size: 1.0625rem;
}

.RecoItem-review {
	font-size: 14px;
	line-height: 15px;
}

.RecoItem-reviewImage.RecoItem-reviewImage.RecoItem-reviewImage.RecoItem-reviewImage {
	@apply tw-inline-block tw-m-0;
}

.RecoItem.RecoItem--tight {
	@apply tw-w-auto tw-max-w-none tw-p-0 tw-min-h-0;
	flex-basis: 0;
}

@screen md {
	.RecoItem.RecoItem--tight {
		width: 224px;
		max-width: 224px;
		min-height: 210px;
		padding: 0 11px;
		flex-basis: 224px;
	}
}

.workspace-modal-frame-attributes {
	@apply tw-mb-5;
}

.workspace-modal-frame-attribute {
	@apply tw-flex tw-my-3;
}

.workspace-modal-frame-attribute-width {
	@apply tw-flex tw-items-center tw-my-3;
}

.workspace-modal-frame-attribute-label {
	@apply tw-inline-block;
	min-width: 75px;
}

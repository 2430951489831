.workspace-product-slot-droppable {
	@apply tw-flex tw-items-center tw-justify-center tw-pointer-events-none tw-relative;
}

.plus-symbol {
	display:inline-block;
	width:30px;
	height:30px;
	background:
	  linear-gradient(#000 0 0),
	  linear-gradient(#000 0 0);
	background-position:center;
	background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50%*/
	background-repeat:no-repeat;
	border-radius:50%;
	border: 1px solid #000;
  }

.workspace-product-slot-droppable-hover {
	&:before {
		@apply tw-absolute tw-border tw-border-solid tw-border-white tw-border-2 tw-bottom-0 tw-m-0 tw-h-full tw-left-0 tw-w-full tw-z-10;
		content: '';
	}
	.plus-symbol {
		background:
	  		linear-gradient(#fff 0 0),
	 		linear-gradient(#fff 0 0),
	 		#000;
		background-position:center;
		background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50%*/
		background-repeat:no-repeat;
	}
}

.workspace-product-slot-droppable-hover-DOUBLE_TALL {
	&:before {
		height: 200%;
	}
}

.workspace-product-slot-droppable-hover-DOUBLE_WIDE {
	&:before {
		width: 200%;
	}
}

.workspace-product-slot-droppable-hover-QUAD {
	&:before {
		height: 200%;
		width: 200%;
	}
}

.workspace-product-slot-droppable-text {
	@apply tw-leading-snug tw-text-center tw-text-white;
	padding: 3px;
}

.workspace-product-slot-droppable-message {
	// z-index 30 to go over the drag preview (z-25)
	@apply tw-bg-white tw-border tw-border-red tw-border-solid tw-text-center tw-whitespace-nowrap tw-z-30;
	position: fixed;
	display: none;
	min-width: 200px;
	padding: 10px;
}

.workspace-product-insert-draggable {
	@apply tw-absolute tw-bottom-0 tw-h-full tw-left-0 tw-pointer-events-auto tw-w-full;
	cursor: grab;

	&:active {
		cursor: grabbing;
	}
}

.workspace-product-insert-draggable-DOUBLE_TALL {
	height: 200%;
}

.workspace-product-insert-draggable-DOUBLE_WIDE {
	width: 200%;
}

.workspace-product-insert-draggable-QUAD {
	height: 200%;
	width: 200%;
}

.selector-value-radio {
	@apply tw-flex tw-items-baseline tw-font-normal;
}

.selector-value-radio-input {
	@apply tw-mr-3 tw-shrink-0;
}

.selector-value-radio-warning {
	@apply tw-block tw-text-red;
}

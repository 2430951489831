@import 'css/lib/tailwind-custom/warning-icon';

.lineItemMessaging {
	@apply tw-space-y-4;
	.lineItemMessage {
		@apply tw-text-gray tw-bg-gray-500 tw-border tw-border-solid tw-border-gray-400 tw-p-2 tw-mb-4;
	}
}
.lineItemMessagingWithIcon {
	@apply tw-space-y-4;
	.lineItemMessage {
		@apply tw-flex;
		&::before {
			@include warning-icon;
		}
	}
}
.lineItemMessagingSmall {
	@apply tw-space-y-4;
	.lineItemMessage {
		@apply tw-flex tw-text-xs md:tw-text-2xs;
	}
}

.workspace-modal-help-steps {
	margin-left: 1rem;
	list-style-type: decimal;
	list-style-position: outside;
}

.workspace-modal-help-step {
	@apply tw-font-bold tw-mb-5;

	ul {
		list-style-type: disc;
		list-style-position: outside;
	}

	* {
		font-weight: normal;
	}
}

.workspace-modal-help-step-cabinets {
	@apply tw-font-bold tw-mb-5;

	ul {
		@apply tw-mt-1;
		list-style-type: disc;
		list-style-position: outside;

		li {
			@apply tw-mb-1;
		}
	}

	* {
		font-weight: normal;
	}
}

.workspace-modal-help-stack {
	@apply tw-ml-4;
	ul {
		@apply tw-mt-1;
		list-style-type: disc;
		list-style-position: outside;

		li {
			@apply tw-mb-1;
		}
	}

	* {
		font-weight: normal;
	}
}

.figure-stack {
	display: flex;
	flex-direction: column;
	img {
		@apply tw-pb-3;
		height: 250px;
		width: unset;
		max-width: unset;
		margin: auto;
	}
}

.workspace-products {
	@apply tw-flex;
} 

.workspace-products-width-products {
	@apply tw-relative;
}

.workspace-products-products {
	@apply tw-flex tw-items-end;
}
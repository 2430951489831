.workspace-product-joinable-droppable {
	@apply tw-basis-0 tw-duration-300 tw-ease-in-out tw-flex tw-flex-shrink-0 tw-h-full tw-items-center tw-justify-center tw-opacity-0 tw-relative tw-transition-opacity tw-w-full;
	transition-property: padding;
}

.workspace-product-joinable-droppable-active {
	@apply tw-border tw-border-dashed tw-border-transparent tw-opacity-100;

	.workspace-product-joinable-droppable-icon {
		background:
			linear-gradient(#000 0 0),
			linear-gradient(#000 0 0);
		background-position:center;
		background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50%*/
		background-repeat:no-repeat;
		border-radius:50%;
		border: 1px solid #000;
	}

	&.workspace-product-joinable-droppable-error {
		.workspace-product-joinable-droppable-icon {
			@apply tw-rotate-45;
			background:
					linear-gradient(#a63535 0 0),
					linear-gradient(#a63535 0 0);
			background-position:center;
			background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50%*/
			background-repeat:no-repeat;
			border: 1px solid #a63535;
		}
	}

	&.workspace-product-joinable-droppable-error-hover {
		@apply tw-bg-red tw-bg-opacity-40;
	
		.workspace-product-joinable-droppable-icon {
			@apply tw-rotate-45;
			background:
					linear-gradient(#a63535 0 0),
					linear-gradient(#a63535 0 0),
					#FFF;
			background-position:center;
			background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50%*/
			background-repeat:no-repeat;
		}
	}
}

.workspace-product-joinable-droppable-hover {
	@apply tw-border-gray;

	.workspace-product-joinable-droppable-icon {
		background:
				linear-gradient(#fff 0 0),
				linear-gradient(#fff 0 0),
				#000;
		background-position:center;
		background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50%*/
		background-repeat:no-repeat;
	}
}

.workspace-product-joinable-droppable-hitbox {
	@apply tw-absolute;
}

.workspace-product-width {
	@apply tw-absolute tw-border-gray tw-border-l tw-border-r tw-border-solid tw-flex tw-items-end tw-justify-center tw-w-full;
	height: 8px;
	top: -20px;

	&:after {
		@apply tw-absolute tw-border-gray tw-border-solid tw-border-t tw-top-1/2 tw-w-full;
		content: '';
	}
}

.workspace-product-width-value {
	@apply tw-z-10;
	@apply tw-bg-white #{!important};
	margin-bottom: -8px;
	pointer-events: none;
}

.workspace-product-width-drag-area {
	position: absolute;
	top: -30px;
	height: 40px;
	width: 100%;
	opacity: 0.5;
}

.workspace-control-enlarge {
	@apply tw-rounded-full;
	height: 36px;
	padding: 3px;
	width: 36px;

	&:hover {
		@apply tw-border tw-border-solid tw-border-gray;

		.workspace-control-enlarge-image-container {
			@apply tw-bg-gray;
		} 

		.workspace-control-enlarge-image {
			filter: invert(1);
		}
	}
}

.workspace-control-enlarge-image-container {
	@apply tw-flex tw-items-center tw-h-full tw-rounded-full tw-justify-center tw-w-full;
}

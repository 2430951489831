.selector-swatch-combined-tabccordion {
    // tabccordion overrides
    :global {
        .Tabccordion-wrapper {
            .Tabccordion-list {
                display: flex !important;

                .Tabccordion-listItem {
                    button {
                        @apply tw-text-base tw-font-normal;
                        &:hover {
                            cursor: pointer;
                        }

                        &.isActive {
                            @apply tw-font-semibold;
                        }
                    }
                }
            }

            .Tabccordion-contentContainer {
                .Tabccordion-content {
                    &.isActive {
                        animation: none;
                        border-bottom: none !important;
                    }
                }
            }
        }
    }
}
.selector-options-group-inserts {
	@apply tw-mb-4;
}

.selector-options-group-inserts-title {
	@apply tw-font-bold;
}

.selector-options-group-inserts-subtitle {
	@apply tw-text-gray-300;
}

.selector-options-group-inserts-selector-values {
	@apply tw-mb-0 tw-mt-2 tw-pl-8;
}

.selector-options-group-inserts-selector-value-upcharge {
	@apply tw-relative;

	&::before {
		@apply tw-absolute;
		content: '+';
		left: -0.75rem;
	}
}

@media screen {
	.selector-promos-container {
		margin: 10px 0;
	}

	.selector-promos {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
	}
}
